<template>
  <div class="home">
    <el-tabs v-model="baseData.activeName" class="demo-tabs">
      <el-tab-pane label="后勤模板说明" name="template">
        <el-scrollbar class="home-scroll">
          <div class="card-box">
            <div class="card-hd">
              <div class="card-hd-left">后勤模板</div>
              <div class="card-hd-right">
              </div>
            </div>
            <div class="card-bd noob-box">
            <p class="noob-lv-1">介绍：用以设定单位时间内店铺的后勤支出</p>
            <p class="noob-lv-1">使用：新增后勤模板 > 进行有效订单设置和人工/快递支出设置 > 保存</p>
            <p class="noob-lv-1">新增后勤模板步骤：</p>
            <p class="noob-lv-2">1、进入后勤模板板块，点击新增SKU成本表按钮</p>
            <p class="noob-lv-2">2、填写后勤模板名称，设定有效订单所囊括的订单状态(勾选的即为非真实订单)，设定人工/快递支出</p>
            <p class="noob-lv-2">3、点击保存按钮</p>
            <p class="greylight2">备注1：编辑后勤模板、货损百分比不在此版本功能范畴内，此版本仅做灰度测试，如效果理想，后续会进行相应迭代升级</p>
            </div>
          </div>
        </el-scrollbar>
      </el-tab-pane>
      <el-tab-pane label="SKU成本说明" name="skucost">
        <div class="card-box">
          <div class="card-hd">
            <div class="card-hd-left">SKU成本</div>
            <div class="card-hd-right">
              <a href="https://donkey.agreatthinking.com/excel/SKU%E6%88%90%E6%9C%AC%E8%A1%A8-%E6%A8%A1%E6%9D%BF.xlsx"
                target="_blank" class="a-default"
              >
                下载SKU成本表模板
              </a>
            </div>
          </div>
          <div class="card-bd noob-box">
            <p class="noob-lv-1">介绍：用以记录单位时间内店铺的SKU成本</p>
            <p class="noob-lv-1">使用：新增SKU成本表 > 根据SKU成本表模板规则，在本地导入相应SKU成本表数据并上传、保存至云端</p>
            <p class="noob-lv-1">新增成本表步骤：</p>
            <p class="noob-lv-2">1、进入SKU成本板块，点击新增SKU成本表按钮</p>
            <p class="noob-lv-2">2、填写SKU成本表名称，上传SKU成本数据 或 手动添加/删除SKU成本数据</p>
            <p class="noob-lv-2">3、点击保存按钮</p>
            <p class="noob-lv-1">编辑成本表步骤：</p>
            <p class="noob-lv-2">1、进入SKU成本板块，选择对应SKU成本表的编辑按钮</p>
            <p class="noob-lv-2">2、SKU成本表名称不可更改</p>
            <p class="noob-lv-2">3、上传SKU成本数据覆盖旧数据 或 手动添加/删除SKU成本数据</p>
            <p class="noob-lv-2">4、点击保存编辑按钮</p>
            <p class="greylight2">备注1：由于sku成本表数据较多，上传excel文件或点击编辑时耗时较久，请耐心等待（亲测5000条sku数据上传完毕耗时8秒左右，编辑界面加载完毕耗时11秒左右）</p>
            <p class="greylight2">备注2：海量数据的情况下(比如sku有5000条数据)，时间/空间复杂度较高，容易卡顿，因此不建议使用"添加一行"，"删除"功能，最好是直接导表保存，数据量小的情况下自行使用</p>
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane label="运营费用说明" name="opareate">
        <div class="card-box">
          <div class="card-hd">
            <div class="card-hd-left">运营费用</div>
            <div class="card-hd-right">
              <a href="https://donkey.agreatthinking.com/excel/%E5%AF%B9%E8%B4%A6%E4%B8%AD%E5%BF%83%E8%A1%A8-%E6%A8%A1%E6%9D%BF.xlsx"
                target="_blank" class="a-default greylight2"
              >
                下载对账中心表模板
              </a>
              <a href="https://donkey.agreatthinking.com/excel/%E5%95%86%E5%93%81%E6%8E%A8%E5%B9%BF%E8%A1%A8-%E6%A8%A1%E6%9D%BF.xlsx"
                target="_blank" class="a-default"
              >
                下载商品推广表模板
              </a>
            </div>
          </div>
          <div class="card-bd noob-box">
            <p class="noob-lv-1">介绍：用以设定单位时间内(月度)店铺的运营费用情况(对账中心数据+商品推广数据)</p>
            <p class="noob-lv-1">使用：新增运营费用表 > 进行对账中心数据和商品推广数据的上传/获取/设定/编辑 > 保存</p>
            <p class="noob-lv-1">新增运营费用表步骤：</p>
            <p class="noob-lv-2">1、进入运营费用板块，点击新增运营费用表按钮</p>
            <p class="noob-lv-2">2、填写运营费用表名称</p>
            <p class="noob-lv-2">3、自动添加(目前仅局限当前月)：点击"获取对账中心/商品推广表"，在新增弹窗扫对应的店铺二维码，脚本事务会模拟人工操作获取对应的数据并展示在界面上</p>
            <p class="noob-lv-2">4、手动添加：添加一行，勾选删除</p>
            <p class="noob-lv-2">2、点击保存按钮</p>
            <p class="noob-lv-1">编辑运营费用表步骤：</p>
            <p class="noob-lv-2">1、进入运营费用板块，选择对应SKU成本表的编辑按钮</p>
            <p class="noob-lv-2">2、运营费用表名称不可更改</p>
            <p class="noob-lv-2">3、上传对账中心/商品推广表数据覆盖旧数据 或 获取对账中心/商品推广表数据 或 手动添加一行/删除运营费用表数据</p>
            <p class="noob-lv-2">4、点击保存按钮</p>
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane label="报表列表说明" name="list">
        <div class="card-box">
          <div class="card-hd">
            <div class="card-hd-left">报表列表</div>
            <div class="card-hd-right">
            </div>
          </div>
          <div class="card-bd noob-box">
            <p class="noob-lv-1">介绍：将外部报表的统计结果保存起来，在此处进行相应展示</p>
            <p class="noob-lv-1">使用：进入报表列表，进行对应查找、查看详情、删除</p>
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane label="外部报表说明" name="excel">
        <div class="card-box">
          <div class="card-hd">
            <div class="card-hd-left">外部报表</div>
            <div class="card-hd-right">
            </div>
          </div>
          <div class="card-bd noob-box">
            <p class="noob-lv-1">介绍：将后勤模板 + sku成本表 + 运营费用表 + 基础订单表结合起来，用以快速统计单位时间内的财务收支情况，以表格+GUI可视化界面呈出</p>
            <p class="noob-lv-1">使用：进入外部报表，选择对应 后勤模板 + sku成本表 + 运营费用表，再导入基础订单表，导入后则会进行相应统计</p>
            <p class="noob-lv-1">新增外部报表步骤：</p>
            <p class="noob-lv-2">1、进入外部报表板块，选择对应 后勤模板 + sku成本表 + 运营费用表</p>
            <p class="noob-lv-2">2、再导入基础订单表(电商平台导出，在此导入)</p>
            <p class="noob-lv-2">3、保存本次报表分析，填写报表名，保存</p>
            <p class="greylight2">备注1：外部报表不支持编辑，只做统计和展示</p>
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane label="联系我们" name="callme">
        <div class="card-box">
          <div class="card-hd">
            <div class="card-hd-left">联系我们</div>
            <div class="card-hd-right">
            </div>
          </div>
          <div class="card-bd noob-box">
            <p class="noob-lv-1">此版本仅做内部测试使用</p>
            <p class="noob-lv-1">wechat：LeoComedy（备注来意）</p>
            <p class="noob-lv-1"><img :src="qrJpg" style="width:180px; height:180px"/></p>
          </div>
        </div>
      </el-tab-pane>
    </el-tabs>

    <div class="card-box" v-if="false">
      <div class="card-hd">
        <div class="card-hd-left">店铺数据</div>
        <div class="card-hd-right">
          <Refresh @refreshCall="refreshHandle(1)"></Refresh>
        </div>
      </div>
      <div class="card-bd">
        <div class="card-bd-alone">
          <div class="card-bd-alone-item" v-for="(item, index) in aloneList" :key="index">
            <div class="card-bd-alone-item-title">{{item.title}}</div>
            <div class="card-bd-alone-item-num">
              <NumberScroll :value="item.num"></NumberScroll>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="card-box" v-if="false">
      <div class="card-hd">
        <div class="card-hd-left">我的店铺</div>
        <div class="card-hd-right">
          <Refresh @refreshCall="refreshHandle(2)"></Refresh>
        </div>
      </div>
      <div class="card-bd">
        <div
          class="card-bd-item pinduoduo"
          v-for="(item, index) in storeList" :key="index"
          :class="[
            { 'doudian': item.platform === 2 }
          ]"
          @click="goRouter('storeDetail', item)"
        >
          <div class="card-bd-item-top">
            <div class="card-bd-item-name">
              <div class="img-logo">
                <img :src="item.logo" />
              </div>
              <span :title="item.mall_name">{{ item.mall_name }}</span>
            </div>
            <el-tag class="merchant-type" type="primary">
              {{ item.merchant_type_name }}
            </el-tag>
          </div>
          <div class="card-bd-item-bottom">
            <div class="card-bd-item-bottom__item">
              <div class="card-bd-item-bottom__title">实际销售量</div>
              <div class="card-bd-item-bottom__num">{{ item.actual_sales_volume }}</div>
            </div>
            <div class="card-bd-item-bottom__item">
              <div class="card-bd-item-bottom__title">销售订单数</div>
              <div class="card-bd-item-bottom__num">{{ item.orders_count }}</div>
            </div>
            <div class="card-bd-item-bottom__item">
              <div class="card-bd-item-bottom__title">退款订单数</div>
              <div class="card-bd-item-bottom__num">{{ item.refund_orders_count }}</div>
            </div>
            <div class="card-bd-item-bottom__item">
              <div class="card-bd-item-bottom__title">退款金额</div>
              <div class="card-bd-item-bottom__num">{{ item.refund_sales_volume }}</div>
            </div>
            <div class="card-bd-item-bottom__item">
              <div class="card-bd-item-bottom__title">销售金额</div>
              <div class="card-bd-item-bottom__num">{{ item.sales_volume }}</div>
            </div>
          </div>
        </div>
        <div class="card-bd-item add-item" @click="addPlatformHandle">
          <div class="card-bd-item-title">添加店铺</div>
          <div class="card-bd-item-icon">+</div>
        </div>
      </div>
    </div>

    <global-dialog
      :type="baseData.dialogType"
      :isShow="baseData.showDialog"
      @close="closeGlobalDialog"
    ></global-dialog>
  </div>
</template>

<script setup>
// eslint-disable-next-line
import GlobalDialog from '@/components/GlobalDialog/index.vue'
import { postAccessToken } from '@/api/authorize'
import { getStoreInfo, getMallPageList } from '@/api/stores'
import { getUserInfo } from '@/api/user'
import NumberScroll from '@/components/NumberScroll/index.vue'
import Refresh from '@/components/Refresh/index.vue'
import { merchantTypeTag } from '@/utils/tags'
import { ref, reactive, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
// import pkg from '../../../package.json'
import qrJpg from '@/assets/qr.jpg'

const store = useStore()
const router = useRouter()

const baseData = reactive({
  dialogType: 'platform',
  showDialog: false,
  activeName: 'template'
})
// 店铺数据 总 单独卡片
const aloneList = ref([
  { title: '销售金额', num: 10000, desc: '销售金额' },
  { title: '销售订单数', num: 908, desc: '销售订单数' },
  { title: '退款金额', num: 222, desc: '退款金额' },
  { title: '退款订单数', num: 232, desc: '退款订单数' }
])
// 我的店铺卡片
const storeList = ref([])

// 添加店铺事件
const addPlatformHandle = () => {
  baseData.dialogType = 'platform'
  baseData.showDialog = true
}
// 关闭globalDialog组件
const closeGlobalDialog = () => {
  baseData.showDialog = false
}

// 刷新事件
const refreshHandle = (val) => {
  console.log('refreshHandle', val)
  aloneList.value.map(item => {
    item.num += item.num
    console.log(item)
    return item
  })
}

// 提交token
const postAccessTokenHandle = async (params) => {
  const resToken = await postAccessToken(params)
  const resStoreInfo = await getStoreInfo({
    provider: 1
  })

  // console.log(store.getters.getAuthList)
  // store.commit('stores/setStoresInfo', {
  //   id: 111,
  //   token: '224'
  // })
  // store.dispatch('stores/getStoresInfo')
  console.log(resStoreInfo, '获取商铺信息，存储商铺信息相关')
  // console.log('查询店铺是否签署多多进宝协议接口isDDJB 相关，携参access_token')
  // console.log('storage存储相关，vuex or localStorage')
  if (resToken && resStoreInfo) {
    location.replace('/#home')
    return
  }
  postAccessTokenHandle(params)
}

// goRouter
const goRouter = async (type, params) => {
  switch (type) {
    case 'storeDetail':
      // 将当前店铺信息存入localStorage，并跳转到详情页面
      await store.commit('stores/setStoreInfo', params)
      router.push({
        name: 'storeDetail',
        params: {
          id: params.id
        }
      })
      break
  }
}

onMounted(async () => {
  function getParams(url) {
    const urlObject = new URL(url)
    const params = {}
    for (const [key, value] of urlObject.searchParams.entries()) {
      params[key] = value
    }
    return params
  }
  const urlCheck = getParams(window.location.href)
  if ((Object.keys(urlCheck).length > 0) && urlCheck?.code && urlCheck?.state) {
    const { code, state } = urlCheck
    postAccessTokenHandle({
      code,
      state,
      provider: 1
    })
  } else {
    // 获取当前用户信息
    getUserInfo().then(res => {
      console.log('getUserInfo', res)
      // store.commit('user/setUserInfo', res)
    })
    // 获取当前店铺详情信息
    const getMallDateRes = await getMallPageList({
      page: 1,
      page_size: 7,
      mall_name: ''
    })
    if (getMallDateRes?.data?.length > 0) {
      const resData = getMallDateRes.data
      storeList.value = resData
      for (const i in resData) {
        resData[i].merchant_type_name = merchantTypeTag(1, resData[i].merchant_type)
      }
    }
  }

  // if (!!localStorage.getItem('package-version')) {
  //   const storageVersion = localStorage.getItem('package-version')
  //   console.log('当前最新版本号:', pkg.version, '当前版本号:', storageVersion)
  //   const isCurrentVersionLessThanLatest = (current, latest) => {
  //     const currentParts = current.split('.').map(Number)
  //     const latestParts = latest.split('.').map(Number)
  //     return latestParts.every((part, index) => {
  //       return part > currentParts[index]
  //     })
  //   }
  //   if (isCurrentVersionLessThanLatest(storageVersion, pkg.version)) {
  //     console.log('111')
  //   } else {
  //     console.log('222')
  //   }
  // }
})
</script>

<style lang="scss">
@import '~@/styles/variables.scss';
@import '~@/styles/mixin.scss';

.home {
  height: 100%;
  margin: 0 12px;
  overflow: hidden;
  .home-scroll {
    height: calc(100vh - 100px);
  }
  .card-box {
    .card-bd {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      &-item {
        width: calc( 25% - 15px);
        margin: 0 $mainPX12 $mainPX12 0;
        border-radius: $mainPX12;
        padding: $mainPX12;
        background: $mainWhiteColorFulLight;
        cursor: pointer;
        box-shadow:  0px 0px 5px #ffffff;
        transition: all 0.3s;
        &:hover {
          box-shadow:  0px 0px 9px #6F61E630;
        }
        &-top {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 4px;
          color: $mainColor;
          font-weight: bold;
        }
        &-name {
          display: flex;
          align-items: center;
          justify-self: flex-start;
          .img-logo {
            position: relative;
            border-radius: 6px;
            margin-right: 8px;
            overflow: hidden;
            min-width: 50px;
            img {
              width: 50px;
              height: 50px;
            }
          }
          span {
            @include textlimit2;
          }
        }
        .merchant-type {
          margin-left: 8px;
        }
        &-platform {
          margin-right: 8px;
        }
        &-bottom {
          display: flex;
          flex-wrap: wrap;
          color: $mainGreyLight;
          &__item {
            width: 50%;
            font-size: 14px;
            margin-top: 4px;
          }
          &__title {
            font-size: 12px;
          }
          &__num {
            margin-top: 8px;
            font-size: 16px;
            font-weight: bold;
          }
        }
        &.pinduoduo {
          position: relative;
          overflow: hidden;
          &::after {
            content: "拼多多";
            position: absolute;
            font-size: 30px;
            right: 12px;
            top: 6px;
            font-weight: bold;
            color: #a9beff1f;
            letter-spacing: 1px;
          }
        }
        &.doudian {
          position: relative;
          overflow: hidden;
          &::after {
            content: "抖店";
            position: absolute;
            font-size: 30px;
            right: 12px;
            top: 6px;
            font-weight: bold;
            color: #a9beff1f;
            letter-spacing: 1px;
          }
        }
        &.add-item {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          .card-bd-item {
            width: calc(100% - $mainPX24);
            &-title {
              height: auto !important;
              color: $mainColor;
              margin: 12px 0 8px 0;
            }
            &-icon {
              color: $mainColor;
            }
          }
        }
        &:nth-child(1),
        &:nth-child(5),
        &:nth-child(9),
        &:nth-child(13),
        &:nth-child(17),
        &:nth-child(21),
        &:nth-child(25),
        &:nth-child(29),
        &:nth-child(33),
        &:nth-child(37) {
          margin-left: 12px;
        }
      }
    }
  }
  .noob-box {
    padding: 0 12px 12px;
    width: calc(100% - 24px);
    display: flex;
    flex-direction: column;
    gap: 8px;
    .noob-lv {
      &-2 {
        text-indent: 42px;
      }
    }
  }
  .el-tabs__header {
    margin-bottom: 0 !important;
  }
}
</style>
